<template>
  <div>
    <!-- <el-row class="picbox">
      <img src="@/assets/xcst/s1.png"/>
    </el-row> -->
    <el-row class="cc">
      <el-col :span="16" :offset="4">
        <p class="IntroductionTitle">磐石信创生态圈</p>
      </el-col>
      <el-col :span="16" :offset="4" class="sta">
        <div class="fl">
          <p>围绕信创规范，建构磐石生态。磐石与国内主流软硬件解决方案厂家展开全面适配，适配对象涉及各主流国产终端操作系统、版式、流式应用和各类外设产品等，通过适配动作持续优化产品形态和兼容模式，同时与更多云衍生产品相融合，为用户提供更加完善的云桌面解决方案和更加广泛的应用场景。</p>
          <br/>
          <p>除加强应用层级软件适配外，磐石面向底层计算资源也开展深度合作，已经与包括nutanix、vmware、Smart x、青云等国内外主流底层虚拟资源平台开展合作，为用户提供更加丰富的底层选择空间。</p>
        </div>
        <div class="fr">
          <img src="@/assets/xcst/s2.png"/>
        </div>
        <div class="clearfix"></div>
      </el-col>
    </el-row>

    <el-row class="cw">
      <el-col :span="16" :offset="4">
        <p class="IntroductionTitle">磐石云桌面与主流信创操作系统适配互认</p>
        <div class="stb stu">
          <p>磐石云桌面及其相关软件应用产品拥有独立知识产权，并先后完成了与统信、麒麟等国产操作系统的相互适配认证。现阶段正在积极开展与各种类别信创应用软件和国产外设的全面适配。</p>
          <img src="@/assets/xcst/s3.jpg" />
        </div>
      </el-col>
    </el-row>

    <el-row class="cc">
      <el-col :span="16" :offset="4">
        <p class="IntroductionTitle">磐石云桌面支持全面托管SmartX超融合平台</p>
        <div class="stb">
          <p style="margin: 0 auto 20px;width: 70%;">磐石云桌面与smartx达成战略合作，磐石云桌面控制引擎支持全面托管smartx超融合资源，同时在整个云桌面的创建、发布、管理过程中，支持对smartx资源支持全面接管其配置，支持通过控制引擎对其进行全面管理。</p>
          <!-- <img src="@/assets/xcst/s6.png" /> -->
          <img src="@/assets/xcst/aq40.jpg" />
        </div>
      </el-col>
    </el-row>

  </div>
</template>

<script>
export default {
  name:'xcrh',
  created(){
    if (this._isMobile()) {
      // 跳转手机页面
      this.$router.push('xcstMobile')
    }
  },
  methods:{
    // 判断移动|PC端
    _isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    }
  }

}
</script>

<style scoped>
.fl{float:left;}
.fr{float:right;}
.clearfix{clear:both;}
.picbox{width: 100%;}
.picbox img{width: 100%;}
.IntroductionTitle{
  border-left: 6px solid #e40f0f;
  line-height: 50px;
  font-size: 32px;
  padding-left: 30px;
  margin-top: 30px;
}
.stu p{
    width: 44%;
    margin-left: 10%;
    margin-bottom: -100px;
    position: relative;
    top: 45px;
}
.cc{background: #fff;padding-bottom: 30px;}
.cw{background: #f5f5f5;padding-bottom: 30px;}
.sta{margin-top: 20px;}
.sta .fl{width: 50%;padding-top: 0;}
.sta .fl p{font-size: 16px;color: #888;line-height: 28px;text-align: justify;text-indent: 2em;width: 90%;}
.sta .fr{width: 50%;}
.sta .fr img{width: 90%;}
.stb{margin-top: 30px;}
.stb p{font-size: 16px;color: #888;line-height: 28px;text-align: justify;text-indent: 2em;}
.stb img{width: 80%; display: block;margin: 0 auto;}
@media screen and (min-width: 1500px){
  .sta .fl{padding-top: 3%;}
}
@media screen and (min-width: 1600px){
  .sta .fl{padding-top: 7%;}
}
</style>